.container {
   padding: 16px;
   @media screen and (min-width: 1024px) {
      padding: 32px 24px;
      width: 100vw;
      height: 100vh;
      padding: 0;
   }
}

.wrapper {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   position: relative;
   gap: 32px;

   @media screen and (min-width: 768px) {
      padding: 93px 0 42px;
      margin: 0 auto;
   }
}

.wrapperForm {
   display: flex;
   flex-direction: column;

   @media screen and (min-width: 768px) {
      flex-direction: row;
      max-width: 1056px;
   }

   form {
      display: flex;
      flex-direction: column;
      gap: 24px;
      label {
         color: #000;
      }
   }
}

.logo {
   display: flex;
   align-items: center;
   justify-content: center;

   svg {
      width: 100px;
      height: 100px;
   }
}

.title {
   font-size: 24px;
   font-weight: 700;
   line-height: 42px;
   color: #000;
   margin-bottom: 40px;
   @media screen and (min-width: 768px) {
      font-size: 32px;
   }
}

.input {
   margin-bottom: 32px;
}

.left,
.right {
   display: flex;
   flex-direction: column;
   justify-content: center;
   width: 100%;
   padding: 40px 24px;
   @media screen and (min-width: 768px) {
      padding: 40px;
   }
}

.left {
   background-color: #fff;
   @media screen and (min-width: 768px) {
      padding: 90px 40px;
   }
}

.remember {
   display: flex;
   align-items: center;
   gap: 16px;

   p {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: #000;
   }
}

.right {
   background-color: #000;
   color: #fff;
   display: flex;
   align-items: center;
   flex-direction: column;
   gap: 40px;
}

.card {
   display: flex;
   gap: 24px;
   align-items: flex-start;

   .icon {
      border-radius: 100px;
      min-width: 56px;
      height: 56px;
      background-color: var(--Neutral-White-alpha-30, rgba(255, 255, 255, 0.3));
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
         display: flex;
         font-size: 28px;
         width: 28px;
         height: 28px;
         color: #fff;
         fill: #fff;
      }
   }

   .subtitle {
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;
      color: #fff;
      margin-bottom: 8px;
      @media screen and (min-width: 768px) {
         font-size: 22px;
      }
   }

   .cardText {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      color: var(--Neutral-White-alpha-70);
   }
}

.passwordInput {
   display: flex;
   align-items: center;
   background-color: var(--grey700);
   position: relative;
   border-radius: 100px;

   button {
      position: absolute;
      right: 5px;

      svg {
         margin-top: 4px;
         margin-right: 8px;
         color: var(--grey500);

         &:hover {
            color: var(--grey200);
         }
      }
   }
}

.btnLogin {
   display: flex;
   align-items: center;
   gap: 16px;
   display: flex;
   width: 100%;
   justify-content: space-between;

   @media screen and (min-width: 768px) {
      height: 42px;
   }

   button {
      justify-content: center;
   }
}
.noaccount {
   display: none;
   gap: 13px;
   align-items: center;
   @media screen and (min-width: 1024px) {
      display: flex;
   }
   .text {
      color: var(--Neutral-Slate-600, #475569);
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
   }
}

.blocked {
   position: absolute;
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: center;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);

   .title {
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      margin-top: 24px;
      color: black;
   }
}
